<template>
<div>
  <p class="display-1">{{ company.name }}</p>
  <div class="d-lg-flex contact__company-info text-small mb-2">
    <div>
      <p>
        <span class="fas fa-map-marker-alt fa-fw"></span>
        {{ company.street }}
      </p>

      <p>
        <span class="fas fa-fw"></span>
        {{ company.zip }} {{ company.city }}
      </p>
    </div>

    <div>
      <p>
          <span class="fas fa-phone fa-fw"></span>
          <a :href="'tel:' + company.phone"
              title="Rufen Sie uns an">
                {{ company.phone }}
          </a>
      </p>

      <p>
        <span class="fas fa-envelope fa-fw"></span>
        <a :href="'mailto:' + company.email"
           title="Schreiben Sie eine E-Mail">
            {{ company.email }}
        </a>
      </p>
    </div>
  </div>

  <standard-map v-model="mapCenter" :zoom="14" @initialized="onMapLoad"></standard-map>
</div>
</template>

<script>
import StandardMap from "../StandardMap.vue";

export default {
  name: "ContactCompanyInfo",

  components: {
    StandardMap
  },

  data() {
    return {
      mapCenter: null
    }
  },

  computed: {
    company() {
      return this.$yeti.company();
    }
  },

  methods: {
    onMapLoad(o) {
      o.L.marker(o.defaultCenter).addTo(o.map);
      this.mapCenter = o.defaultCenter;
    }
  }
}
</script>
