import Vue from "vue";
import vuetify from "../vuetify.js";
import CookieNotice from "../components/cookie-notice/CookieNotice.vue";

export default () => {
  const element = document.querySelector("#vue-cookie-notice");
  if (element) {
    new Vue({
      el: element,

      components: {
        CookieNotice
      },

      vuetify,

      render: h => h(CookieNotice)
    })
  }
}
