<template>
<v-app class="mt-4 mb-3 mb-lg-10">
  <v-container fluid>
    <v-row>
      <v-col cols="24" lg="12">
      <contact-form :name="name" :required-fields="requiredFields">
          <contact-fields></contact-fields>
      </contact-form>
      </v-col>

      <v-col cols="24" lg="10" offset-lg="2" class="mt-3 mt-lg-0">
        <contact-company-info></contact-company-info>
      </v-col>
    </v-row>
  </v-container>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFields";
import ContactCompanyInfo from "./ContactCompanyInfo";

export default {
  name: "ContactFormApp",

  components: {
    ContactForm,
    ContactFields,
    ContactCompanyInfo
  },

  data() {
    return {
      name: "contact"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  }
};
</script>
