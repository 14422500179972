/* eslint-disable */

export default {
  googleAnalytics(userId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.google-analytics.com/analytics.js";
    document.getElementsByTagName("head")[0].appendChild(s);

    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    window.ga("create", userId, "auto");
    window.ga("set", "anonymizeIp", !0);
    window.ga("send", "pageview");
  },

  googleAds(tagId) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://www.googletagmanager.com/gtag/js?id=" + tagId;
    document.getElementsByTagName("head")[0].appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function gtag() { dataLayer.push(arguments); }
    window.gtag("js", new Date());
    window.gtag("config", tagId);
  }
}
