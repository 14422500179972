import Vue from "vue";
import vuetify from "../vuetify.js";
import ContactApp from "../components/contact/ContactApp.vue";

export default function() {
  const element = document.querySelector("#vue-contact");
  if (element) {
    new Vue({
      el: element,

      components: {
        ContactApp
      },

      vuetify,

      render: h => h(ContactApp)
    })
  }
}

