import Vue from "vue";
import vuetify from "./vuetify.js";
import deps from "./deps";
import yeti from "./yeti";
import MainCss from "./MainCss";

import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
import "@fortawesome/fontawesome-pro/scss/brands.scss";
import "@fortawesome/fontawesome-pro/scss/solid.scss";
import "@fortawesome/fontawesome-pro/scss/light.scss";
import "@fortawesome/fontawesome-pro/scss/regular.scss";

Vue.config.productionTip = false;

Vue.prototype.$yeti = yeti;
Vue.prototype.$yeti.setupTracking();

// lädt das CSS in der richtigen Reihenfolge
new Vue({
  vuetify,
  components: {
    MainCss
  }
});

Object.keys(deps.entrypoints).forEach(entrypoint => {
  const fn = require("./entrypoints/" + entrypoint + ".js");
  fn.default();
});
